import React from 'react';
import PropTypes from 'prop-types';

import { useModal } from './ModalContext';

const ModalBackground = ({ children }) => {
  const [, dismiss] = useModal();

  return (
    <div
      className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center inset-0 z-50 overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
      onClick={dismiss}
    >
      <div className="modal-overlay absolute w-full h-full bg-brown-05 opacity-50"></div>

      <div className="modal-container bg-white w-11/12 md:max-w-448 mx-auto rounded shadow-lg z-50 overflow-y-auto">
        <div
          className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-15 mr-15 text-white text-sm z-50"
          onClick={dismiss}
        >
          <svg
            className="fill-current text-white w-18 h-18"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
          <span className="text-sm">(Esc)</span>
        </div>

        {children}
      </div>
    </div>
  );
};

ModalBackground.propTypes = {
  children: PropTypes.object.isRequired,
};

export default ModalBackground;
