import React from 'react';
import PropTypes from 'prop-types';

import { useModal } from './ModalContext';

const ModalContent = ({ children }) => {
  const [, dismiss] = useModal();

  return (
    <div className="modal-content py-18 text-left px-25">
      <div className="flex flex-row-reverse justify-between items-center pb-3">
        <div className="modal-close cursor-pointer z-50" onClick={dismiss}>
          <svg
            className="fill-current text-gray-300 w-15 h-15"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>
      {children}
    </div>
  );
};

ModalContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ModalContent;
