import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';

const ModalContext = createContext(undefined);

export const ModalProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [type, setType] = useState(undefined);
  const [props, setProps] = useState(undefined);

  const showModal = useCallback((modalType, modalProps) => {
    const body = document.querySelector('body');
    body.classList.add('modal-active');
    setShow(true);
    setType(modalType);
    setProps(modalProps);
  }, []);

  const dismissModal = useCallback(() => {
    const body = document.querySelector('body');
    body.classList.remove('modal-active');
    setShow(false);
    setTimeout(() => {
      setType(undefined);
      setProps(undefined);
    }, 300);
  }, []);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('keydown', event => {
        event = event || window.event;
        let isEscape = false;

        if ('key' in event) {
          isEscape = event.key === 'Escape' || event.key === 'Esc';
        } else {
          isEscape = event.keyCode === 27;
        }
        if (isEscape && document.body.classList.contains('modal-active')) {
          dismissModal();
        }
      });
    }
  }, [dismissModal]);

  return (
    <ModalContext.Provider value={{ showModal, dismissModal }}>
      <Modal isVisible={show} type={type} {...props} />
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.object,
};

export const useModal = type => {
  const { showModal, dismissModal } = useContext(ModalContext);

  return [props => showModal(type, props), dismissModal];
};
