import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useModal } from './ModalContext';
import ModalContent from './ModalContent';

const DefaultModal = props => {
  const {
    title,
    onConfirm,
    onConfirmText,
    onCancelText,
    onCancel,
    body,
  } = props;
  const [, dismiss] = useModal();

  function onCancelClick() {
    onCancel();
    dismiss();
  }

  function onConfirmClick() {
    onConfirm();
    dismiss();
  }

  return (
    <ModalContent>
      {/* Body */}
      <p className="text-center mb-10 mt-0 font-pt-sans block text-gray-400 font-bold text-lg leading-tight text-gray-800 sm:mb-10">
        {title}
      </p>
      {body !== undefined ? body : null}
      {/* Footer */}
      <div className="flex justify-center pt-28">
        {onCancelText && (
          <button
            type="button"
            onClick={onCancelClick}
            className={clsx(
              'outline-none focus:outline-none no-underline hover:text-white text-center items-center inline-flex justify-center border font-pt-sans font-bold rounded-full text-gray-300 border-gray-300 hover:bg-gray-300 h-40 px-25 w-105 text-root'
            )}
          >
            <span>{onCancelText}</span>
          </button>
        )}
        {onConfirmText && (
          <button
            type="button"
            className={clsx(
              'outline-none focus:outline-none ml-10 no-underline hover:text-white text-center items-center inline-flex justify-center border font-pt-sans font-bold rounded-full bg-secondary-400 border-secondary-400 hover:bg-secondary-500 hover:border-secondary-500 w-105 text-white h-40 px-25 text-root'
            )}
            onClick={onConfirmClick}
          >
            <span>{onConfirmText}</span>
          </button>
        )}
      </div>
    </ModalContent>
  );
};

DefaultModal.defaultProps = {
  // tslint:disable-next-line:no-empty
  onCancel: () => {},
  // tslint:disable-next-line:no-empty
  onConfirm: () => {},
};

DefaultModal.propTypes = {
  body: PropTypes.any,
  onCancel: PropTypes.func,
  onCancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onConfirmText: PropTypes.string,
  title: PropTypes.string,
};

export default DefaultModal;
