import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

// Components
import ModalBackground from './ModalBackground';
import DefaultModal from './DefaultModal';

// Modals
export const ModalType = {
  default: DefaultModal,
};

const Modal = ({ isVisible, type, ...props }) => {
  const currentModal = React.createElement(ModalType[type], props);

  return (
    <CSSTransition
      in={isVisible}
      timeout={300}
      classNames="modal-"
      unmountOnExit={true}
      mountOnEnter={true}
      appear={true}
      enter={true}
      exit={true}
    >
      <ModalBackground>{currentModal}</ModalBackground>
    </CSSTransition>
  );
};

Modal.defaultProps = {
  type: 'default',
};

Modal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

export default Modal;
