/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { ModalProvider } from './src/components/Modals/ModalContext';

import './src/assets/styles/globals.css';

// eslint-disable-next-line
export const wrapRootElement = ({ element }) => {
  // Start the mocking conditionally.
  // if (process.env.NODE_ENV === 'development') {
  //   const { worker } = require('./jest-configs/__mocks__/browser')
  //   worker.start({
  //     onUnhandledRequest: 'bypass',
  //   })
  // }

  return <ModalProvider>{element}</ModalProvider>;
};
